@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
  font-family: 'ProximaNovaBold';
  src: url('./fonts/ProximaNovaBold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('./fonts/ProximaNovaRegular.otf') format('opentype');
  font-weight: normal;
}

body {
  color: rgb(255,255,255);
  background: #0C0E13;
  font-family: "ProximaNova";
  font-weight: bold;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

html {
  scroll-behavior: smooth;
}

a {
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  font-family: "ProximaNovaBold";
}

button {
  font-size: 40px;
  font-weight: 400;
  line-height: 40px;
}

h1 {
  font-size: 64px;
  font-weight: 700;
  line-height: 80px;
  font-family: "Montserrat";
}

h2 {
  font-size: 28px;
  font-weight: 100;
  line-height: 28px;
}

h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  font-family: "ProximaNovaBold";
}

h4 {
  font-size: 15px;
  font-weight: 700;
  line-height: 15px;
}

h5 {
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  display: inline;
}

p {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}